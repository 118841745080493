import React from "react"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Testimonials from "../components/testimonials/Testimonials";

const ClientTestimonialsPage = () => (
    <Layout>
        <SEO title="Client Testimonials Page" />
        <div className='container'>
            <Testimonials />
        </div>
    </Layout>
)

export default ClientTestimonialsPage;