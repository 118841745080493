import React from 'react'

import styles from './Testimonials.module.css';
import BlockHeader from '../block-header/BlockHeader';
import {clientTestimonials} from './mockData'

const testimonialsData = {
  title: 'Client Testimonials',
  description: ''
}

const TestimonialsBlock = () => {
  return (
    <div className={styles.testimonials}>
      <div>
        <BlockHeader header={testimonialsData}/>
      </div>
      <div className={styles.content}>
          {clientTestimonials.map((client) => {
              return (
                  <div className={styles.article} key={client.title}>
                      <img src={client.img} className={styles.img} />
                      <div className={styles.title}>{client.title}</div>
                      <div className={styles.intro}>{client.description}</div>
                  </div>
              )
          })}
        </div>
    </div>
  )
}

export default TestimonialsBlock